<template>
  <v-container fluid>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-text-field
          hide-details
          prepend-icon="fas fa-search"
          single-line
          label="Buscar..."
          @input="indexData()"
          v-model="query"
        ></v-text-field>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ dataTable.length }} Cliente(s)
        </v-chip>
        <v-chip
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'clientes/formulario' }"
        >
          Novo
        </v-chip>
      </v-toolbar>
    </div>

    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="dataLoading"
      ></v-progress-circular>
    </div>
    <Item v-for="item in dataTable" :key="item.id" :item="item" :list="headers">
      <template v-slot:acoes>
        <v-btn icon color="green" :to="{ path: 'clientes/' + item.id }">
          <v-icon>far fa-eye</v-icon>
        </v-btn>
        <v-btn icon color="red" @click="selectFile(item)">
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          :to="{ path: `clientes/${item.id}/formulario` }"
        >
          <v-icon>fa fa-edit</v-icon>
        </v-btn>
      </template>
    </Item>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">
            Você deseja remover esse cliente ?
          </v-card-title>

          <v-card-text>
            <b> Nome </b> {{ dataToModal.nome_cliente }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="dialog = false"> Fechar </v-btn>
            <v-btn color="green" @click="deleteData()"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import ToastService from "@/services/ToastService";
import ClienteService from "@/services/Cliente/ClienteService";
import AuthorizationService from "@/services/AuthorizationService";
import Item from "@/components/CardListItem/Item";

export default {
  name: "ClienteIndex",

  created: async function() {
    await this.indexData();
  },

  components: {
    Item,
  },

  data: () => ({
    selected: [],
    query: "",
    dataTable: [],
    loading: true,
    dialog: false,
    confirmData: {},
    showDeleteModal: false,
    showForm: false,
    dataLoading: true,
    dataToModal: {},
    paginateParams: {},
    AuthorizationService: AuthorizationService,
    ClienteService: new ClienteService(""),
    headers: [
      { title: "NAME", nick: "Nome" },
      { title: "NOME_ADMINISTRADORA", nick: "Administradora" },
      { title: "CNPJ", nick: "CNPJ" },
    ],
  }),

  methods: {
    async indexData(queryString = "") {
      console.log(queryString);
      try {
        this.dataLoading = true;

        const res = await this.ClienteService.index(
          "customers?search=" + this.query
        );

        this.dataTable = res;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.dataLoading = false;
      }
    },

    async deleteData() {
      try {
        await this.ClienteService.destroy("customers/" + this.dataToModal.id);

        ToastService("Ação realizada com sucesso.", "success");

        await this.indexData();
      } catch (exception) {
        ToastService("Erro ao realizar operação.", "error");
      } finally {
        this.loading = false;
      }
    },

    selectFile(item) {
      this.dataToModal = item;

      this.dialog = true;
    },

    destroy() {
      this.ClienteService.destroy("customers/" + this.dataToModal.id);
    },

    verifyDeleteData(item) {
      this.confirmData = item;
      this.showDeleteModal = true;
    },

    async confirmDeleteData(item) {
      this.loading = true;

      this.cancelDeleteData();

      await this.deleteData(item.id);
    },

    cancelDeleteData() {
      this.confirmData = {};
      this.dialog = true;
    },

    redirectForm(path) {
      this.$router.push({ path: path });
    },

    async setPage(page) {
      await this.indexData(`?page=${page}`);
    },
  },
};
</script>
